import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../../components/template-wrapper";
import ContactForm from "../../components/contact-form";

export default function sciSocContact ({data}) {
  const accumulation = "";

  return (
    <div className="palette--cst-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation} category={"science-society"} >
      <ContactForm />   
      {/* <div style={{paddingBottom: "20rem"}}/>            */}
    </TemplateWrapper>
    </div>
  );
};

export const pageQuery = graphql`
  query sciSocContactQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/science-society/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( 
            layout: FULL_WIDTH, 
            placeholder: BLURRED
          )  
        }
      } 
    }
  }
`;
